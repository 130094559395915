var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"container-fuild mt-2"},[_c('ol',{staticClass:"breadcrumb text-big py-3 m-0"},_vm._l((_vm.breadCrumbs),function(item){return _c('li',{key:item.id,staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.get(item.id)}}},[_vm._v(_vm._s(item.name))])])}),0),_c('hr',{staticClass:"m-0"}),_c('div',{staticClass:"file-manager-actions py-1"},[_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":() => {
            _vm.view = _vm.VIEW_LIST;
          }}},[_c('i',{staticClass:"fa fa-list"})]),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":() => {
            _vm.view = _vm.VIEW_GRID;
          }}},[_c('i',{staticClass:"fa fa-th"})]),(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_FOLDERS))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openNewFolderModal()}}},[_c('i',{staticClass:"fa fa-folder"}),_vm._v("  "+_vm._s(_vm.$t("FILES.NEW_FOLDER"))+" ")]):_vm._e(),(
          _vm.$currentUserCan(_vm.$permissions.PERM_CREATE_FILES) &&
          !!_vm.currentFolder.id
        )?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openNewFileModal()}}},[_c('i',{staticClass:"fa fa-upload"}),_vm._v("  "+_vm._s(_vm.$t("FILES.NEW_FILE"))+" ")]):_vm._e(),(_vm.canDoActions())?_c('base-dropdown',[_c('base-button',{staticClass:"dropdown-toggle",attrs:{"slot":"title-container","type":"info"},slot:"title-container"},[_c('i',{staticClass:"fa fa-cog"})]),_c('li',[(_vm.canEdit())?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.openEditModal()}}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" "+_vm._s(_vm.$t("FILES.EDIT"))+" ")]):_vm._e(),(_vm.canViewHistory())?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.openFileHistoryModal()}}},[_c('i',{staticClass:"ni ni-book-bookmark"}),_vm._v(" "+_vm._s(_vm.$t("FILES.FILE_HISTORY"))+" ")]):_vm._e(),(_vm.canDelete())?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteItems()}}},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("FILES.REMOVE"))+" ")]):_vm._e()])],1):_vm._e()],1),_c('hr',{staticClass:"m-0"})]),(_vm.view == _vm.VIEW_GRID)?_c('files-grid',{key:_vm.renderKey,attrs:{"folder":_vm.currentFolder,"folderData":_vm.folderData},on:{"onNavigateTo":(id) => {
        _vm.get(id);
      },"onSelectItems":(items) => {
        _vm.selectedItems = items;
      }}}):_vm._e(),(_vm.view == _vm.VIEW_LIST)?_c('files-list',{key:_vm.renderKey,attrs:{"folder":_vm.currentFolder,"folderData":_vm.folderData},on:{"onNavigateTo":(id) => {
        _vm.get(id);
      },"onSelectItems":(items) => {
        _vm.selectedItems = items;
      }}}):_vm._e(),(_vm.showFolderFormModal)?_c('folder-form-modal',{attrs:{"currentFolder":_vm.currentFolder,"folderData":_vm.folderData,"showModal":_vm.showFolderFormModal},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.currentFolder.id && _vm.showAddFileFormModal)?_c('add-file-form-modal',{attrs:{"currentFolder":_vm.currentFolder,"showModal":_vm.showAddFileFormModal},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.currentFolder.id && _vm.showFileFormModal)?_c('file-form-modal',{attrs:{"currentFolder":_vm.currentFolder,"fileData":_vm.fileData,"showModal":_vm.showFileFormModal},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.currentFolder.id)?_c('file-history-modal',{attrs:{"fileData":_vm.fileData,"showModal":_vm.showFileHistoryModal},on:{"onCloseModal":_vm.closeModal}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }