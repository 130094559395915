<template>
  <div class="file-manager-container file-manager-list container-fuild mt-5">
    <div class="row">
      <div class="col-12 mb-4 row item-row">
        <div class="col-1 folder-icon"></div>
        <div class="col-7 row px-0 details">
          <div class="file-item-name col-12">{{ $t("COMMON.NAME") }}</div>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at"> {{ $t("COMMON.UPDATED_AT") }} </span>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at"> {{ $t("COMMON.CREATED_AT") }} </span>
        </div>
      </div>

      <div class="col-12 mb-1 row" v-if="folder.id">
        <div class="col-1">
          <div
            class="file-item file-item-folder"
            @dblclick="navigateToParent()"
          >
            <div class="file-item-content">
              <div class="file-item-icon">
                <i class="fa fa-reply"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11"></div>
      </div>

      <div
        v-for="folder in folder.subfolders"
        :key="`folder-${folder.id}`"
        class="col-12 mb-1 row item-row"
      >
        <div class="col-1 folder-icon">
          <div
            class="file-item file-item-folder"
            :class="[
              {
                selected: selectedItems.some(
                  (item) => item.id === folder.id && item.type == 'folders'
                ),
                locked: folder.locked,
              },
            ]"
            @dblclick="navigateToFolder(folder.id)"
            @click="selectItem(folder)"
          >
            <div class="file-item-content">
              <div class="file-item-icon">
                <i class="fa fa-folder"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 row px-0 details">
          <div class="file-item-name col-12">
            {{ folder.name }}
          </div>
          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
              folder.organization
            "
            class="file-item-organization col-12"
          >
            <span class="btn btn-sm btn-info">
              <i class="ni ni-building"></i>
              {{ folder.organization.name }}
            </span>
          </div>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at">
            {{ $formatDate(folder.updated_at) }}
          </span>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at">
            {{ $formatDate(folder.created_at) }}
          </span>
        </div>
      </div>
      <div
        v-for="file in folder.files"
        :key="`file-${file.id}`"
        class="col-12 mb-1 row item-row"
      >
        <div class="col-1 file-icon">
          <div
            class="file-item file-item-folder"
            :class="[
              {
                selected: selectedItems.some(
                  (item) => item.id === file.id && item.type == 'files'
                ),
              },
            ]"
            @dblclick="viewFile(file)"
            @click="selectItem(file)"
          >
            <div class="file-item-content">
              <div class="file-item-icon">
                <i class="fa fa-file"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-7 row px-0 details">
          <div class="file-item-name col-12">
            {{ file.name }}
          </div>
          <div
            v-if="
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
              folder.organization
            "
            class="file-item-organization col-12"
          >
            <span class="btn btn-sm btn-info">
              <i class="ni ni-building"></i>
              {{ file.organization.name }}
            </span>
          </div>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at">
            {{ $formatDate(file.updated_at) }}
          </span>
        </div>
        <div class="col-2 details-right">
          <span class="updated-at">
            {{ $formatDate(file.created_at) }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: {
    folder: {
      type: Object,
      default: null,
      description: "Current folder",
    },
  },

  computed: {},

  watch: {},

  data() {
    return {
      selectedItems: [],
    };
  },

  methods: {
    navigateToFolder(id) {
      this.selectedItems = [];
      this.$emit("onNavigateTo", id);
    },
    navigateToParent() {
      if (this.folder.parent) {
        this.navigateToFolder(this.folder.parent.id);
      } else {
        this.navigateToFolder(null);
      }
    },

    selectItem(selectedItem) {
      if (this.selectedItems.some((item) => item.id === selectedItem.id)) {
        this.selectedItems = this.selectedItems.filter(
          (item) =>
            item.id !== selectedItem.id && item.type !== selectedItem.type
        );
      } else {
        this.selectedItems.push(selectedItem);
      }
      this.$emit("onSelectItems", this.selectedItems);
    },

    viewFile(file) {
      window.open(file.url, "_blank");
    },
  },
};
</script>

<style lang="sass">
.file-manager-list
  .item-row
    .details, .details-right
      border-bottom: 1px solid var(--gray)

  .details-right
    display: flex
    align-items: center
    flex-direction: row-reverse

  .file-item-name
    font-size: 1.2rem
    font-weight: 900
    display: flex
    align-items: center

  .file-item
    position: relative
    padding-bottom: 100%
    background-color: var(--gray)
    border-radius: 5px
    text-align: center
    color: #000
    cursor: pointer

    .file-item-organization
      height: 100%
      width: 100%
      position: absolute
      display: flex
      flex-direction: column-reverse
      flex-wrap: nowrap
      align-items: stretch
      justify-content: flex-start

    &.selected
      box-shadow: 0px 0px 10px var(--primary)
      &:before
        content: "\f14a"
        font-family: "Font Awesome 5 Free"
        font-size: 24px
        font-weight: 900
        color: var(--primary)
        position: absolute
        top: 5px
        left: 5px
        transform: translate(-50%, -50%)

    &.locked
      &:not(.selected)
        box-shadow: 0px 0px 2px var(--info)
      &:after
        content: "\f023"
        font-family: "Font Awesome 5 Free"
        font-size: 25px
        font-weight: 900
        color: var(--info)
        position: absolute
        top: 5px
        right: -15px
        transform: translate(-50%, -50%)

    &-content
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: 0
      width: 100%
      height: 100%
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      font-size: 2rem
      user-select: none

      .file-item-icon
        line-height: 0
      .file-item-name
        font-size: 1rem
        line-height: 1rem
        text-overflow: ellipsis
        overflow: hidden
        width: 100%
        padding: 5px
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        height: 2.5rem
</style>
