<template>
  <div class="container-fluid">
    <div class="container-fuild mt-2">
      <ol class="breadcrumb text-big py-3 m-0">
        <li v-for="item in breadCrumbs" :key="item.id" class="breadcrumb-item">
          <a href="#" @click="get(item.id)">{{ item.name }}</a>
        </li>
      </ol>
      <hr class="m-0" />
      <div class="file-manager-actions py-1">
        <button
          type="button"
          class="btn btn-info"
          @click="
            () => {
              view = VIEW_LIST;
            }
          "
        >
          <i class="fa fa-list"></i>
        </button>
        <button
          type="button"
          class="btn btn-info"
          @click="
            () => {
              view = VIEW_GRID;
            }
          "
        >
          <i class="fa fa-th"></i>
        </button>

        <button
          type="button"
          class="btn btn-primary"
          @click="openNewFolderModal()"
          v-if="$currentUserCan($permissions.PERM_CREATE_FOLDERS)"
        >
          <i class="fa fa-folder"></i>&nbsp;
          {{ $t("FILES.NEW_FOLDER") }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-if="
            $currentUserCan($permissions.PERM_CREATE_FILES) &&
            !!currentFolder.id
          "
          @click="openNewFileModal()"
        >
          <i class="fa fa-upload"></i>&nbsp;
          {{ $t("FILES.NEW_FILE") }}
        </button>
        <base-dropdown v-if="canDoActions()">
          <base-button
            slot="title-container"
            type="info"
            class="dropdown-toggle"
          >
            <i class="fa fa-cog"></i>
          </base-button>
          <li>
            <a
              class="dropdown-item"
              href="#"
              v-if="canEdit()"
              @click="openEditModal()"
            >
              <i class="fa fa-edit"></i>
              {{ $t("FILES.EDIT") }}
            </a>
            <a
              class="dropdown-item"
              href="#"
              v-if="canViewHistory()"
              @click="openFileHistoryModal()"
            >
              <i class="ni ni-book-bookmark"></i>
              {{ $t("FILES.FILE_HISTORY") }}
            </a>
            <a
              class="dropdown-item"
              href="#"
              @click="deleteItems()"
              v-if="canDelete()"
            >
              <i class="fa fa-trash"></i>
              {{ $t("FILES.REMOVE") }}
            </a>
          </li>
        </base-dropdown>
      </div>

      <hr class="m-0" />
    </div>

    <files-grid
      v-if="view == VIEW_GRID"
      :key="renderKey"
      :folder="currentFolder"
      :folderData="folderData"
      @onNavigateTo="
        (id) => {
          get(id);
        }
      "
      @onSelectItems="
        (items) => {
          selectedItems = items;
        }
      "
    />

    <files-list
      v-if="view == VIEW_LIST"
      :key="renderKey"
      :folder="currentFolder"
      :folderData="folderData"
      @onNavigateTo="
        (id) => {
          get(id);
        }
      "
      @onSelectItems="
        (items) => {
          selectedItems = items;
        }
      "
    />

    <!-- MODALS -->
    <folder-form-modal
      v-if="showFolderFormModal"
      :currentFolder="currentFolder"
      :folderData="folderData"
      :showModal="showFolderFormModal"
      @onCloseModal="closeModal"
    />

    <add-file-form-modal
      v-if="currentFolder.id && showAddFileFormModal"
      :currentFolder="currentFolder"
      :showModal="showAddFileFormModal"
      @onCloseModal="closeModal"
    />

    <file-form-modal
      v-if="currentFolder.id && showFileFormModal"
      :currentFolder="currentFolder"
      :fileData="fileData"
      :showModal="showFileFormModal"
      @onCloseModal="closeModal"
    />

    <file-history-modal
      v-if="currentFolder.id"
      :fileData="fileData"
      :showModal="showFileHistoryModal"
      @onCloseModal="closeModal"
    />
  </div>
</template>

<script>
import swal from "sweetalert2";
import requestErrorMixin from "@/mixins/request-error-mixin";
import FilesGrid from "./partials/FilesGrid.vue";
import FolderFormModal from "./partials/FolderFormModal.vue";
import FileFormModal from "./partials/FileFormModal.vue";
import FileHistoryModal from "./partials/FileHistoryModal.vue";
import FilesList from "./partials/FilesList.vue";
import AddFileFormModal from "./partials/AddFileFormModal.vue";

const VIEW_GRID = "GRID";
const VIEW_LIST = "LIST";

export default {
  components: {
    FolderFormModal,
    FilesGrid,
    FileFormModal,
    FileHistoryModal,
    FilesList,
    AddFileFormModal,
  },

  mixins: [requestErrorMixin],

  computed: {},

  data() {
    const currentFolderData = {
      id: null,
      name: "/",
      subfolders: [],
      files: [],
      allowedLocations: [],
      roles: [],
      users: [],
      organization: {
        type: "organizations",
        id: null,
      },
    };

    const me = this.$store.getters["profile/me"];

    return {
      me: me,
      breadCrumbs: [],
      currentFolder: currentFolderData,
      showFolderFormModal: false,
      showFileFormModal: false,
      showAddFileFormModal: false,
      folderData: null,
      fileData: null,
      showFileHistoryModal: false,
      selectedItems: [],
      renderKey: 0,
      view: VIEW_GRID,
      VIEW_GRID: VIEW_GRID,
      VIEW_LIST: VIEW_LIST,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get(id = null) {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      if (id) {
        await this.getFolder(id);
      } else {
        await this.getRootFolder();
      }
      this.selectedItems = [];
      swal.close();
    },

    async getRootFolder() {
      try {
        const params = {
          sort: "name",
          filter: {
            parent: null,
          },
          page: {
            number: 0,
            size: 999999,
          },
          include: "organization,tags,allowedLocations",
        };

        await this.$store.dispatch("folders/list", params);
        const folders = this.$store.getters["folders/list"];
        const folder = {
          id: null,
          name: "/",
          subfolders: folders,
          files: [],
          allowedLocations: [],
          roles: [],
          users: [],
          organization: {
            type: "organizations",
            id: null,
          },
        };
        this.currentFolder = folder;
      } catch (error) {
        this.showRequestError(error);
      }
    },

    async getFolder(id) {
      try {
        await this.$store.dispatch("folders/get", id);
        this.currentFolder = this.$store.getters["folders/folder"];
      } catch (error) {
        this.showRequestError(error);
      }
    },

    openNewFolderModal() {
      this.closeModal();
      this.showFolderFormModal = true;
    },

    openNewFileModal() {
      this.closeModal();
      this.showAddFileFormModal = true;
    },

    async openFileHistoryModal() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      this.closeModal();
      try {
        await this.$store.dispatch("files/get", this.selectedItems[0].id);
        this.fileData = this.$store.getters["files/file"];
        this.showFileHistoryModal = true;
      } catch (error) {
        this.showRequestError(error);
      }
      swal.close();
    },

    async openEditModal() {
      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });
      this.closeModal();
      const selectedItem = this.selectedItems[0];
      if (selectedItem.type === "folders") {
        await this.openEditFolderModal(selectedItem);
      } else if (selectedItem.type === "files") {
        await this.openEditFileModal(selectedItem);
      }
      swal.close();
    },

    async openEditFolderModal(selectedItem) {
      try {
        await this.$store.dispatch("folders/get", selectedItem.id);
        this.folderData = this.$store.getters["folders/folder"];
        this.showFolderFormModal = true;
        this.renderKey = this.renderKey + 1;
      } catch (error) {
        this.showRequestError(error);
      }
    },

    async openEditFileModal(selectedItem) {
      try {
        await this.$store.dispatch("files/get", selectedItem.id);
        this.fileData = this.$store.getters["files/file"];
        this.showFileFormModal = true;
        this.renderKey = this.renderKey + 1;
      } catch (error) {
        this.showRequestError(error);
      }
    },

    async closeModal(refresh) {
      if (refresh) {
        await this.get(this.currentFolder.id);
      }
      this.showFolderFormModal = false;
      this.folderData = null;
      this.showAddFileFormModal = false;
      this.showFileFormModal = false;
      this.fileData = null;
      this.showFileHistoryModal = false;
    },

    async deleteItems() {
      const confirmation = await swal.fire({
        title: this.$t("FILES.DELETE_THIS_ITEM"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        if (confirmation.value === true) {
          for (const item of this.selectedItems) {
            if (item.type === "folders") {
              await this.$store.dispatch("folders/destroy", item.id);
            } else if (item.type === "files") {
              await this.$store.dispatch("files/destroy", item.id);
            }
          }
          await this.get(this.currentFolder.id);
          this.$notify({
            type: "success",
            message: this.$t("LOCATIONS.LOCATION_DELETED"),
          });
        }
        this.renderKey = this.renderKey + 1;
      } catch (error) {
        this.showRequestError(error);
      }

      swal.close();
    },

    canViewHistory() {
      if (this.selectedItems.length === 1) {
        if (this.selectedItems[0].type === "files") {
          return true;
        }
      }
      return false;
    },

    canEdit() {
      const selectedItemsHasLockedFolder = this.selectedItems.some((item) => {
        if (item.type === "folders") {
          return item.locked;
        }
        return false;
      });
      if (selectedItemsHasLockedFolder) {
        if (
          !this.$currentUserCan(this.$permissions.PERM_MANAGE_LOCKED_FOLDERS)
        ) {
          return false;
        }
      }

      if (this.selectedItems.length === 1) {
        if (this.selectedItems[0].type === "files") {
          return this.$currentUserCan(this.$permissions.PERM_EDIT_FILES);
        }
        if (this.selectedItems[0].type === "folders") {
          return this.$currentUserCan(this.$permissions.PERM_EDIT_FOLDERS);
        }
      }
      return false;
    },

    canDelete() {
      const selectedItemsHasLockedFolder = this.selectedItems.some((item) => {
        if (item.type === "folders") {
          return item.locked;
        }
        return false;
      });
      if (selectedItemsHasLockedFolder) {
        if (
          !this.$currentUserCan(this.$permissions.PERM_MANAGE_LOCKED_FOLDERS)
        ) {
          return false;
        }
      }

      for (const item of this.selectedItems) {
        if (item.type === "files") {
          if (!this.$currentUserCan(this.$permissions.PERM_EDIT_FILES)) {
            return false;
          }
        }
        if (item.type === "folders") {
          if (!this.$currentUserCan(this.$permissions.PERM_EDIT_FOLDERS)) {
            return false;
          }
        }
      }
      return true;
    },

    canDoActions() {
      const selectedItemsHasLockedFolder = this.selectedItems.some((item) => {
        if (item.type === "folders") {
          return item.locked;
        }
        return false;
      });
      if (selectedItemsHasLockedFolder) {
        if (
          !this.$currentUserCan(this.$permissions.PERM_MANAGE_LOCKED_FOLDERS)
        ) {
          return false;
        }
      }

      return this.selectedItems.length > 0;
    },
  },

  watch: {
    currentFolder(currentFolder) {
      const currentFolderIndex = this.breadCrumbs.findIndex(
        (item) => item.id === currentFolder.id
      );
      if (currentFolderIndex >= 0) {
        this.breadCrumbs = this.breadCrumbs.slice(0, currentFolderIndex + 1);
      } else {
        this.breadCrumbs.push({
          id: currentFolder.id,
          name: currentFolder.name,
        });
      }
    },
  },
};
</script>

<style scoped>
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #8898aa;
  content: ">";
}
</style>
